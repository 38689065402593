<template>
  <div class="site-body">
    <section class="presale-fixed-section">
      <p>
        <span>You purchased </span>
        <strong>{{ localeValue(purchasedAmount) }} {{ prjConst('tokenName') }}</strong>
        <button class="btn btn-secondary" @click="scroll('buy-token', 180)">BUY</button>
      </p>
    </section>
    <section class="presale-section presale-section-1">
      <div class="container container-max-lg">
        <div class="cont-row">
          <div class="text-cont">
            <h1>{{ prjConst('tokenName') }} Presale</h1>
            <h4 v-if="endOfSale"><span class="mr-3">Closed</span></h4>
            <h4 v-else-if="!presaleLive"><span>Starts after</span></h4>
            <div class="count-down-wrap" v-if="presaleLive">
              <div>
                <p class="num">LIVE</p>
              </div>
            </div>

            <!-- <h4 v-else-if="presaleLive">
              <span class="mr-3">Round {{ roundNo }} / {{ maxRound }} </span>
              <span> Live</span>
            </h4> -->

            <div class="count-down-wrap" v-if="!presaleLive">
              <div>
                <p class="tit">Day</p>
                <p class="num">{{ countDays }}</p>
              </div>
              <div>
                <p class="tit">Hours</p>
                <p class="num">{{ countHours }}</p>
              </div>
              <div>
                <p class="tit">Minutes</p>
                <p class="num">{{ countMins }}</p>
              </div>
              <div>
                <p class="tit">Seconds</p>
                <p class="num">{{ countSecs }}</p>
              </div>
            </div>
          </div>
          <div class="img-cont">
            <img src="img/img-billy-rocket.png" />
          </div>
        </div>
        <!-- 
        <h2>Tokens Sold</h2>
        <div class="sold-tokens-wrap">
          <div class="row text-wrap">
            <div class="col-auto">
              <p v-if="endOfSale">{{ prjConst('tokenName') }} Presale Closed</p>
              <p v-else>{{ prjConst('tokenName') }} Presale Round {{ roundNo }} / {{ maxRound }}</p>
            </div>
            <div class="col-auto">
              <h5 v-if="endOfSale">100 %</h5>
              <h5 v-else>{{ ethValue(nSoldTokenVolume) }} / {{ ethValue(nTokenVolume) }} {{ prjConst('tokenName')}} ({{ localeValue(soldProgress * 100) }} %)</h5>
            </div>
          </div>
          <div class="sold-tokens-progress-wrap">
            <div v-if="endOfSale" class="sold-tokens-progress" style="width: 100%"></div>
            <div v-else class="sold-tokens-progress" :style="`width: ${soldProgress * 100}%`"></div>
          </div>
        </div>
         -->
      </div>
    </section>
    <section class="presale-section presale-section-2">
      <div class="container container-max-lg">
        <div class="balance-box" v-if="!endOfSale" id="buy-token">
          <div class="img-cont">
            <img src="img/img-billy-safe.png" />
          </div>
          <div class="input-cont">
            <div class="row align-items-center mb-sm-5 mb-3 padding-1">
              <div class="col">
                <div class="row mb-4 mb-sm-0">
                  <div class="col col-sm-12">
                    <p class="text-gray">Your Balance</p>
                  </div>
                  <div class="col-auto"><h5>{{ localeValue(tetherBalance) }} USDT</h5></div>
                </div>
              </div>
              <div class="col-12 col-sm-auto" v-if="!endOfSale">
                <div class="row justify-content-end">
                  <div class="col-auto">
                    <button class="btn btn-secondary" @click="setTetherAmount(50)">50%</button>
                  </div>
                  <div class="col-auto">
                    <button class="btn btn-secondary" @click="setTetherAmount(100)">All</button>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="!endOfSale">
              <div class="my-2">
                <div class="input-group">
                  <input
                    type="number"
                    class="form-control text-right text-700 form-control-lg"
                    placeholder="0.0"
                    v-model="tetherAmount"
                    :max="maxTetherAmount"
                    @focus="focusAt = 'coin'"
                    @blur="adjustTetherAmount()"
                  />
                  <div class="input-group-text text-700">USDT</div>
                </div>
              </div>
              <p class="text-center text-gray py-1 py-sm-3">{{ prjConst('tokenName') }} = {{ tokenRatio }} USDT</p>
              <div class="my-2">
                <div class="input-group">
                  <input
                    type="number"
                    class="form-control text-right text-700 form-control-lg"
                    placeholder="0.0"
                    v-model="tokenAmount"
                    @focus="focusAt = 'token'"
                    :aria-label="prjConst('tokenName')"
                  />
                  <div class="input-group-text text-700">{{ prjConst('tokenName') }}</div>
                </div>
              </div>
              <div class="row mt-4">
                <div class="col" v-if="! permitted">
                <button class="btn btn-info btn-block btn-lg btn-shadow pl-1 pr-1" @click="permitUsdt()" :disabled="tetherBalance == 0">
                  Permit USDT
                </button>
                </div>
                <div class="col">
                <button class="btn btn-primary btn-block btn-lg btn-shadow" @click="buyToken()" :disabled="tetherBalance == 0 || !permitted">
                  Confirm
                </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>


  </div>
</template>

<style scoped>
.presale-section-2 .balance-box .input-cont .btn.btn-primary {
    position: relative;
}
.presale-section {
  padding-top: 1px;
  padding-bottom: 40px;
}
.presale-section-2 {
  margin-top: 0px;
}
</style>


<script>
import PresaleMixin from '@/mixins/presale.mixin.vue'
import ProjectConstants from '@/config/constants.js'
import PrjConst from '@/mixins/prjconst.js'
import UiScroll from '@/mixins/ui-scroll'
import moment from 'moment';

let presaleStartDate = ProjectConstants.schedulePresaleStart;
let presaleEndDate = ProjectConstants.schedulePresaleEnd;
let dueDate = presaleStartDate;
if (Date.now() > presaleStartDate.getTime()) {
  dueDate = presaleEndDate;
}
const zeroPad = (num, places) => String(num).padStart(places, '0');

export default {
  mixins: [ PrjConst, PresaleMixin, UiScroll ],
  setup(props, context) {},
  computed: {
    currentRoute: {
      get() {
        // console.log("Current route = ", this.$route.name);
        return this.$route.name;
      },
    },
  },
  data() {
    return {
      countDays: '00',
      countHours: '00',
      countMins: '00',
      countSecs: '00',
      timer: null,
      presaleLive: false,
    }
  },
  unmounted() {
    let vm = this;
    if (this.timer) {
      clearInterval(vm.timer);
      vm.timer = null;
    }
  },
  async mounted() {
    if (Date.now() > presaleStartDate.getTime()) {
      this.presaleLive = true;
    }
    else {
      this.presaleLive = false;
    }

    this.runCountdown();
  },
  methods: {
    runCountdown() {
      let vm = this;

      if (vm.endOfSale) {
        vm.countDays = '00';
        vm.countHours = '00';
        vm.countMins = '00';
        vm.countSecs = '00';
        vm.timer = null;
        return;
      }

      let n = moment();
      let dd = moment(dueDate.getTime());

      let diffDays = dd.diff(n, 'days');
      vm.countDays = zeroPad(diffDays, 2);

      let dur = moment.duration(dd.diff(n));
      vm.countHours = zeroPad(dur.get('hours'), 2);
      vm.countMins = zeroPad(dur.get('minutes'), 2);
      vm.countSecs = zeroPad(dur.get('seconds'), 2);

      vm.timer = setTimeout(() => {
        vm.runCountdown()
      }, 1000);
    }

  },
};
</script>
